import React from 'react'
import Layout from '../../components/layout'
import SEO from '../../components/seo'
import LinkGrid from '../../components/terms/link-grid'
import PolicyDetails from '../../components/terms/terms-of-service'

const TermsOfService = ({ location }) => {
  return (
    <Layout>
      <SEO title='Terms and Conditions' pageType='terms' flowType='none' />
      {typeof window !== 'undefined' && (
        <LinkGrid pathname={location.pathname} />
      )}
      <PolicyDetails />
    </Layout>
  )
}

export default TermsOfService
